
const reqHeaders = new Headers();
reqHeaders.append('Content-Type', 'application/json');
const baseUrl = process.env.REACT_APP_API_URL;
const url = `${baseUrl}/api`
export class ApiService {
    static async register(customerData) {
        const requestOptions = {
            method: 'POST',
            headers: reqHeaders,
            body: JSON.stringify(customerData),
        };

        try {
            const respJson = await fetch(`${url}/user/register`, requestOptions);
            return await respJson.json();
        } catch (error) {
            return error.message;
        }
    }

    static async validateCustomer(id) {
        const requestOptions = {
            method: 'GET',
            headers: reqHeaders,
        };
        try {
            const respJson = await fetch(`${url}/user/validate/${id}`, requestOptions);
            return await respJson.json();
        } catch (error) {
            return error.message;
        }
    }

    
}
