import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import thankYou from '../../assets/images/thankYou.png';

const CheckEmail = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behaviour: 'smooth',
    });
    if(!location?.state?.redirected){
      navigate('/');
    }
  });

  return (
    <div className="checkmail-box ">
      <img
        src={thankYou}
        alt="thankYou"
        className="img img-responsive thank-you mb-5"
        width="350"
      />

      <div className="register-box mx-auto">
        <p className="register-description p-4 pb-0">
          Thanks for registering your interest to be part of the Inch's free
          pint campaign! We've sent you an email with a link to confirm your
          participation in the campaign.
        </p>
        <p className="register-description pt-4 pb-4 mx-5">
          If you haven't received an email please check your junk mail.
        </p>
      </div>
    </div>
  );
};

export default CheckEmail;
