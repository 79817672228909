import React, { useEffect, useState } from 'react';
import { Col, Form, Button, Row } from 'react-bootstrap';
import helper from '../../helpers/staticData';
import { useNavigate } from 'react-router-dom';
import { ApiService } from '../../Services/ApiService';
import './RegisterForm.css';
import NumberFormat from 'react-number-format';
export const RegisterForm = (props) => {
  const navigate = useNavigate();
  const [formValid, setformValid] = useState(false);
  const [signupFail, setSignupFail] = useState(false);
  const [registerData, setRegisterData] = useState({
    outletName: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    county: '',
    postCode: '',
    country: 'England',
    contactName: '',
    email: '',
    phoneNumber: '',
    holderName: '',
    sortCode: '',
    accountNumber: '',
    serveValue: '',
  });
  const [errorStatus, setErrorStatus] = useState({});

  const validateForm = () => {
    const {
      outletName,
      addressLine1,
      addressLine2,
      city,
      county,
      postCode,
      country,
      contactName,
      email,
      phoneNumber,
      holderName,
      sortCode,
      accountNumber,
      serveValue,
    } = registerData;
    const emailValid = errorStatus.email;

    setformValid(
      outletName &&
        (addressLine1 || addressLine2) &&
        city &&
        county &&
        postCode &&
        country &&
        contactName &&
        email &&
        !emailValid &&
        phoneNumber &&
        holderName &&
        sortCode.length === 6 &&
        accountNumber &&
        serveValue,
    );
  };

  useEffect(() => {
    validateForm();
  }, [registerData]);

  const addPrefix = (e) => {
    let { value } = e.target;
    let tempValue = value.split('£')[1] ? value.split('£')[1] : value;
    if (isNaN(tempValue)) {
      return value.slice(0, -1);
    } else if (!value.startsWith('£') && value.length !== 0) {
      value = '£' + value.toString();
    }
    if (value.length === 1) {
      value = '';
    }
    let splittedValue = value.split('.');
    if(splittedValue[1]){
      let formattedValue = splittedValue[0] + '.' + splittedValue[1].slice(0,2);
      return formattedValue;
    }
    return value;
  };

  const checkPhoneNumber = (e) => {
    let { value } = e.target;
    if (isNaN(value)) {
      return value.slice(0, -1);
    }
    return value;
  };

  const handleSortCodeChange = (obj) => {
    const tempData = { ...registerData };
    tempData['sortCode'] = obj.value;
    const e = {
      target: {
        name: 'sortCode',
        value: obj.value,
      },
    };
    setRegisterData(tempData);
    validateField(e);
  };

  const updateSortCode = (e) => {
    let { value } = e.target;
    const result = value.match(/^[0-9]*$/);
    if (!result) {
      return value.slice(0, -1);
    }
    return value.slice(0, 6);
  };

  const handleInputChange = (e) => {
    const tempData = { ...registerData };
    let { name, value } = e.target;
    if (name === 'sortCode') {
      value = updateSortCode(e);
    }
    if (name === 'accountNumber') {
      value = value.slice(0, 8);
    }
    if (name === 'phoneNumber') {
      value = checkPhoneNumber(e);
    }
    if (name === 'serveValue') {
      value = addPrefix(e);
    }
    tempData[name] = value;
    setRegisterData(tempData);
    validateField(e);
  };

  const validateField = (e) => {
    const { value, name } = e.target;
    const tempData = { ...errorStatus };
    if (name === 'email') {
      validateEmail(e);
    } else if (name === 'sortCode') {
      if (value.length !== 6) {
        tempData[name] = true;
      } else {
        tempData[name] = false;
      }
      setErrorStatus(tempData);
    } else {
      if (value === '' || value.startsWith(' ')) {
        tempData[name] = true;
      } else {
        tempData[name] = false;
      }
      setErrorStatus(tempData);
    }
  };

  const validateEmail = (e) => {
    const { name, value } = e.target;
    const tempData = { ...errorStatus };
    const result = value.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
    if (result) {
      tempData[name] = false;
    } else {
      tempData[name] = true;
    }
    setErrorStatus(tempData);
  };

  const onSubmit = async () => {
    const response = await ApiService.register(registerData);
    if (response && response?.result) {
      navigate('success', {
        state: {
          redirected: true,
        },
      });
    }
    setSignupFail(true);
  };

  return (
    <div className="register-box  mx-auto">
      <p className="register-description p-5  mb-0">
        Please fill out your details below for your outlet to participate in the
        Inch's Free Pint campaign, running from 1st June to 31st July 2022
      </p>
      <Form className="pb-3">
        <div className="outlet-section mb-3">
          <h5>OUTLET ADDRESS</h5>
          <Form.Group className="m-3">
            <Form.Control
              type="text"
              name="outletName"
              placeholder="Outlet Name"
              value={registerData.outletName}
              onChange={handleInputChange}
              onBlur={validateField}
            />
            <p
              hidden={!errorStatus.outletName}
              className="text-start text-danger ms-2 mt-1 mb-0 fw-bold"
            >
              Please enter your outlet name
            </p>
          </Form.Group>

          <Form.Group className="m-3">
            <Form.Control
              type="text"
              name="addressLine1"
              placeholder="Address Line 1"
              value={registerData.addressLine1}
              onChange={handleInputChange}
              onBlur={validateField}
            />
          </Form.Group>
          <Form.Group className="m-3 mb-0">
            <Form.Control
              type="text"
              name="addressLine2"
              placeholder="Address Line 2"
              value={registerData.addressLine2}
              onChange={handleInputChange}
              onBlur={validateField}
            />
            <p
              hidden={!errorStatus.addressLine1 || !errorStatus.addressLine2}
              className="text-start text-danger ms-2 mt-1 mb-0 fw-bold"
            >
              Please enter your address
            </p>
          </Form.Group>
          <Row className="p-0">
            <Col sm={12} lg={6} className="padding-end">
              <Form.Group className="m-3 mb-0 margin-end">
                <Form.Control
                  type="text"
                  value={registerData.city}
                  onChange={handleInputChange}
                  onBlur={validateField}
                  name="city"
                  placeholder="City"
                />
                <p
                  hidden={!errorStatus.city}
                  className="text-start text-danger ms-2 mt-1 mb-0 fw-bold"
                >
                  Please enter your city
                </p>
              </Form.Group>
            </Col>
            <Col sm={12} lg={6} className="padding-start">
              <Form.Group className="m-3 mb-0 margin-start">
                <Form.Control
                  type="text"
                  name="county"
                  placeholder="County"
                  value={registerData.county}
                  onChange={handleInputChange}
                  onBlur={validateField}
                />
                <p
                  hidden={!errorStatus.county}
                  className="text-start text-danger ms-2 mt-1 mb-0 fw-bold"
                >
                  Please enter your county
                </p>
              </Form.Group>
            </Col>
            <Col sm={12} lg={6} className="padding-end">
              <Form.Group className="m-3 mb-0 margin-end">
                <Form.Control
                  type="text"
                  name="postCode"
                  placeholder="Postcode"
                  value={registerData.postCode}
                  onChange={handleInputChange}
                  onBlur={validateField}
                />
                <p
                  hidden={!errorStatus.postCode}
                  className="text-start text-danger ms-2 mt-1 mb-0 fw-bold"
                >
                  Please enter your postcode
                </p>
              </Form.Group>
            </Col>
            <Col sm={12} lg={6} className="padding-start">
              <Form.Group className="m-3 margin-start">
                <Form.Select
                  type="text"
                  name="country"
                  onChange={handleInputChange}
                  onBlur={validateField}
                  disabled
                >
                  {helper.getCountries().map((country, i) => (
                    <option key={i} value={country}>
                      {country}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
        </div>

        <div className="contact-section mb-3">
          <h5>CONTACT</h5>
          <Form.Group className="m-3">
            <Form.Control
              type="text"
              name="contactName"
              placeholder="Contact Name"
              value={registerData.contactName}
              onChange={handleInputChange}
              onBlur={validateField}
            />
            <p
              hidden={!errorStatus.contactName}
              className="text-start text-danger ms-2 mt-1 mb-0 fw-bold"
            >
              Please enter your contact name
            </p>
          </Form.Group>
          <Form.Group className="m-3">
            <Form.Control
              type="email"
              name="email"
              value={registerData.email}
              onChange={handleInputChange}
              onBlur={validateEmail}
              placeholder="Contact Email Address"
            />
            <p
              hidden={!errorStatus.email}
              className="text-start text-danger ms-2 mt-1 mb-0 fw-bold"
            >
              Please enter a valid email address
            </p>
          </Form.Group>
          <Form.Group className="m-3">
            <Form.Control
              type="text"
              name="phoneNumber"
              maxLength="11"
              value={registerData.phoneNumber}
              onChange={handleInputChange}
              onBlur={validateField}
              placeholder="Contact Mobile Number"
            />
            <p
              hidden={!errorStatus.phoneNumber}
              className="text-start text-danger ms-2 mt-1 mb-0 fw-bold"
            >
              Please enter your mobile number
            </p>
          </Form.Group>
        </div>

        <div className="reimbursement-section mb-3 pt-3">
          <h5>REIMBURSEMENT DETAILS</h5>
          <p
            className="p-3 mx-auto w-75 text-center  fw-bold"
            style={{ letterSpacing: '0.5px' }}
          >
            We need this information to pay you for any vouchers redeemed in
            your outlet. Account holder name can be a first name and last name
            or an enterprise name
          </p>
          <Form.Group className="m-3 mb-0">
            <Form.Control
              type="text"
              name="holderName"
              placeholder="Account Holder Name"
              value={registerData.holderName}
              onChange={handleInputChange}
              onBlur={validateField}
            />
            <p
              hidden={!errorStatus.holderName}
              className="text-start text-danger ms-2 mt-1 mb-0 fw-bold"
            >
              Please enter your bank account holder name
            </p>
          </Form.Group>
          <Row className="p-0">
            <Col sm={12} lg={6} className="padding-end">
              <Form.Group className="m-3 mb-0 margin-end">
                <NumberFormat
                  onValueChange={handleSortCodeChange}
                  placeholder="Sort Code"
                  className="form-control"
                  format="##-##-##"
                  mask=""
                />
                <p
                  hidden={!errorStatus.sortCode}
                  className="text-start text-danger ms-2 mt-1 mb-0 fw-bold"
                >
                  Please enter your bank account sort code
                </p>
              </Form.Group>
            </Col>
            <Col sm={12} lg={6} className="padding-start">
              <Form.Group className="m-3 margin-start">
                <Form.Control
                  type="number"
                  name="accountNumber"
                  placeholder="Account Number"
                  value={registerData.accountNumber}
                  onChange={handleInputChange}
                  onBlur={validateField}
                />
                <p
                  hidden={!errorStatus.accountNumber}
                  className="text-start text-danger ms-2 mt-1 mb-0 fw-bold"
                >
                  Please enter your bank account number
                </p>
              </Form.Group>
            </Col>
          </Row>
        </div>

        <div className="serve-section mb-3">
          <h5>SERVE VALUE</h5>
          <p className="p-3 mx-2 fw-bold" style={{ letterSpacing: '0.5px' }}>
            What is the serve value of a pint of Inch's in your outlet?
          </p>
          <Form.Group className="m-3 mb-0 ">
            <Form.Control
              type="text"
              name="serveValue"
              placeholder="Serve Value"
              value={registerData.serveValue}
              onChange={handleInputChange}
              onBlur={validateField}
            />
          </Form.Group>
          <p
            hidden={!errorStatus.serveValue}
            className="text-start text-danger ms-2 mt-1 ps-3 mb-0 fw-bold "
          >
            Please enter your serve value
          </p>
        </div>

        <div className="w-100 p-3">
          <p className="text-center color-cream mt-1 px-2 mb-4 pb-2  fw-bold">
            For information on Heineken UK Limited look after your personal
            data, please review our{' '}
            <a
              href="https://direct.heineken.co.uk/huk/en/privacy_policy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            .{' '}
          </p>
          <Button
            className="w-100 submit-button fw-bold"
            onClick={onSubmit}
            disabled={!formValid}
          >
            SUBMIT
          </Button>
          <p
            hidden={!signupFail}
            className="text-center text-danger ms-2 mt-3 mb-0 fw-bold"
          >
            Email is already registered.
          </p>
        </div>
      </Form>
    </div>
  );
};
