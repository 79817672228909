import React from 'react';
import './Footer.css';
import footerImage from '../../assets/images/footerImage.png';
import inchFooter from '../../assets/images/inchFooter.png';
import drinkAware from '../../assets/images/drinkaware.png';
export const Footer = (props) => {
  return (
    <>
      <div className="mt-5 px-0 footer-main" style={{position:'relative'}}>
        <img
          src={footerImage}
          className="img footer-image"
          alt="footer"
          style={{position:'relative'}}
        />
        <img src={inchFooter} className='img inch-footer'  alt='inchFooter'/>
        <img src={drinkAware} className='img drinkaware' alt='drinkaware'/>

      </div>

      <div className="m-0 px-3 footer w-100 text-center py-3 text-white fw-bold">
        <p className="mt-1">
          Heineken UK Limited, Registered Company Number SCO65527, Registered
          Office 3-4 Broadway Park, EH12 9JZ
        </p>
        <p>
          By entering this site, you agree to our <a href="/terms">Terms of Use</a>{' '}
          and acknowledge that you have read and understood our{' '}
          <a href="https://direct.heineken.co.uk/huk/en/privacy_policy" target="_blank" rel='noreferrer'>Privacy Policy</a>
        </p>
        <p className="mb-0">©HP Bulmer Limited, 2022. All Rights Reserved</p>
      </div>
    </>
  );
};

export default Footer;
