import React, { Component } from 'react';
import { Routes, Route,Navigate} from 'react-router-dom';
import CheckEmail from './Components/CheckEmail/CheckEmail';
import {RegisterForm} from './Components/RegisterForm/RegisterForm';
import { TermsComponent } from './Components/Terms/Terms';
import ThankYou from './Components/ThankYou/ThankYou';
import { VerifyCustomer } from './Components/VerifyCustomer/VerifyCustomer';
import MainPage from './MainPage/MainPage';



export const App = (props) => {
  return(
    <Routes>
        <Route path="/thank-you" element={<MainPage component={<ThankYou/>} />}/>
        <Route path="/validate/:id" element={<VerifyCustomer/>}/>
        <Route path="/success" element={<MainPage component={<CheckEmail />}/>}/>
        <Route path="/" element={<MainPage component={<RegisterForm />}/>} />
        <Route path="/terms" element={<MainPage component={<TermsComponent />}/>} />

        <Route path="*" element={<Navigate to="/"/>} />
      </Routes>
   )
 }

export default App;
