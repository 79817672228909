import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import thankYou from '../../assets/images/thankYou.png';
const ThankYou = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behaviour: 'smooth',
    });
    if(!location?.state?.redirected){
      navigate('/');
    }
  });

  return (
  
      <div className="thankyou-box">
        <img
          src={thankYou}
          alt="thankYou"
          className="img img-responsive thank-you mb-5"
          width="350"
        />
      
      <div className="register-box mx-auto">
        <p className="register-description pt-4 pb-0">
          We're just verifying your details.
        </p>
        <p className="register-description  py-3 mx-5">
        We will be in touch with the full campaign details and supporting campaign activation materials.
        </p>
      </div>
      </div>
  
  );
};

export default ThankYou;
