import React, { useEffect } from 'react'
import {useParams,useNavigate} from 'react-router-dom';
import {ApiService} from '../../Services/ApiService';

export const VerifyCustomer = (props) => {

  const {id} = useParams();
  const navigate = useNavigate();

  useEffect( ()=>{
    async function verify(){
      const response = await ApiService.validateCustomer(id);
      if(response && response.result && response.data){
          navigate('/thank-you',{state:{
            redirected:true
        }})
      }else{
        navigate('/');
      }
    }
    verify();
  })

  return('')

 }