  import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import './MainPage.css';
import appleLeft from '../assets/images/appleLeft.png';
import appleRight from '../assets/images/appleRight.png';
import Footer from '../Components/Footer/Footer';
import Header from '../Components/Header/Header';
class MainPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { component } = this.props;
    return (
      <>
        <div className="main-div">
          <div className="with-background"></div>
          <div className="border-left-image"></div>
          <div className="border-right-image"></div>
          <img src={appleLeft} alt="apples" className="apple-left-image" />
          <img src={appleRight} alt="apples" className="apple-right-image" />

          <Row className="mx-auto main-row">
            <Col xs={12} md={12} lg={12} className="text-center">
              <Header />
              <Col className="content-box mx-auto" lg={4} xs={10} md={8}>
                {component}
              </Col>
            </Col>
          </Row>
          <Footer />

        </div>
      </>
    );
  }
}

export default MainPage;
