import React from 'react';
import logo from '../../assets/images/logo.svg';
import './Header.css';
import {useNavigate} from 'react-router-dom';


export const Header = (props) => {
  const navigate = useNavigate()
  return(
    <div
      className="header-logo"
      onClick={() => {
        navigate('/')
      }}
    >
      <img src={logo} alt="header-logo" className="img img-responsive"  width='100%'/>
    </div>
  );

 }

export default Header;
